@import "./Colors.scss";


.input-group-1 {
    .form-control {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $primaryColor;
        background-color: transparent;

        &:focus {
            border: none;
        border-bottom: 2px solid $primaryColor;
        background-color: transparent;
        }
    }

    .input-group-text {
        border: 2px solid $primaryColor;
        background-color: transparent;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

.custom-input-1 {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $primaryColor;
    

    &:focus {
        border-bottom: 2px solid $primaryColor;
    }
}


.custom-select-1 {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $primaryColor;
    

    &:focus {
        border-bottom: 2px solid $primaryColor;
    }
}


.form-control-1 {
    border-bottom: 2px solid lightgrey;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;


    &:focus {
        border-bottom: 2px solid $primaryColor ;
    }
}