@import "./Colors.scss";


.notifications-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20;

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        cursor: pointer;
    }

    .notifications {
        position: fixed !important;
        right: 0 !important;
        top: 0 !important;
        height: 100vh;
        width: 350px;
        background-color: white;
        padding: 40px;
        animation-name: show-not;
        animation-iteration-count: 1;
        animation-duration: 0.4s;

        @keyframes show-not {
            0% {
                transform: translate(100%,0);
                
            }

            100% {
                transform: translate(0,0);
            }
        }


        .btn-close {
            position: absolute;
            right: 10px !important; 
            top: 10px !important;
        }
    }
}


.notifications {
 

    .notifications-counter {
      position: absolute;
        left: 100%;
        top: 100%;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $primaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(-50%, -50%);

        p {
            color: white;
            
        }
    }
}