.dropzone {
  border: none !important;
  background-color: var(--mui-palette-background-default);
  min-height: auto !important;
  padding: var(--border-radius) !important;
  padding-top: var(--border-radius) !important;
  padding-bottom: var(--border-radius) !important;
  border-radius: var(--border-radius);
  border: 2px dashed var(--mui-palette-info-main) !important;
  transition: 0.3s;

  & :global(.MuiGrid-item) {
    box-shadow: none !important;
    outline: none !important;
    padding: 0 !important;
    padding-top: 10px !important;
    max-width: 300px;
    min-width: 300px;
  }

  & :global(.MuiDropzonePreviewList-image) {
    box-shadow: none !important;
    border-radius: 12;
  }

  & :global(.MuiFab-circular) {
    top: -10px;
    right: 0;
    box-shadow: none;
    outline: none;
  }

  & :global(.MuiGrid-container) {
    padding-top: 20px;
    border-top: 1px solid lightgray;
    margin: 0 !important;
    margin-top: 20px !important;
    justify-content: center;
    display: flex;
    max-width: 100%;
  }
}

:global(.MuiDropzoneArea-active) {
  border-color: #ff7f00; /* Darker shade of orange */
  background-color: rgba(255, 165, 0, 0.1); /* Light orange background */

  /* Gradient Background Animation */
  background-image: linear-gradient(
    45deg,
    rgba(255, 165, 0, 0.1) 25%,
    #fff 25%,
    #fff 50%,
    rgba(255, 165, 0, 0.1) 50%,
    rgba(255, 165, 0, 0.1) 75%,
    #fff 75%,
    #fff
  );
  background-size: 50px 50px;
  animation: slide 2s linear infinite;
}

/* Pulse Animation */
@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50px 50px;
  }
}
