.flag-container {
  width: 30px;
  height: 25px;
  max-height: 30px;
  max-width: 35px;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
}

.flag {
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dropdown-toggle::after {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
