.logo-dropzone {
  width: 100%;
  height: 100%;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  border-color: lightgrey !important;
  background-color: white;
}

.cover-dropzone {
  width: 100%;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  border-color: lightgrey !important;
  height: 100%;
  background-color: white;
}
