.add-menu-dropzone {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  border-color: lightgrey !important;
  & p {
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }

  & :global(.MuiGrid-item) {
    box-shadow: none !important;
    outline: none !important;
    padding: 0 !important;
    padding-top: 10px !important;
    max-width: 80px;
    min-width: 80px;
  }
}
