.balls {
  position: absolute;
  bottom: 0;
  transform: translate(0, 70px);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  img {
    width: 60px;
    height: 60px;
    animation-name: raise;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes raise {
    0% {
      transform: translateY(0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      transform: translateY(-80vh);
      opacity: 0;
    }
  }

  @-webkit-keyframes raise {
    0% {
      transform: translateY(0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      transform: translateY(-80vh);
      opacity: 0;
    }
  }

  img:nth-child(1) {
    animation-delay: 2s;
  }

  img:nth-child(2) {
    animation-delay: 0s;
  }

  img:nth-child(3) {
    animation-delay: 4s;
  }

  img:nth-child(4) {
    animation-delay: 7s;
  }

  img:nth-child(5) {
    animation-delay: 9s;
  }

  img:nth-child(6) {
    animation-delay: 3s;
  }

  img:nth-child(7) {
    animation-delay: 5s;
  }

  img:nth-child(8) {
    animation-delay: 1s;
  }

  img:nth-child(9) {
    animation-delay: 10s;
  }
}
