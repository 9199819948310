.category-item {
  height: 30px;
  line-height: 26px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  cursor: pointer;
  padding-left: 20px !important;
  padding-right: 20px !important;

  background-color: #ffffff !important;
  color: #000 !important;
  font-weight: 500 !important;
  border: 1px solid #000000 !important;
  border-radius: 20px;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  transition: 0.3s;
}

/* .category-item:hover {
  background-color: #ffffff !important;
  color: #000 !important;
  font-weight: 500 !important;
  border: 1px solid #000000 !important;
} */
.category-item:active {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.is-sticky {
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 999;
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100% !important;
  border: 1px solid var(--mui-palette-divider);
}

.is-selected {
  background-color: #000000 !important;
  color: #ffffff !important;
}

.category-section {
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 500px) {
  .is-sticky {
    position: fixed;
    top: 56px;
    left: 0;
    width: 100%;
    margin: 0px;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .category-section {
    justify-content: flex-start;
  }
}
