@import "./Colors.scss";


.fade-in-new {
    animation-name: fade-in-new;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}

@keyframes fade-in-new {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

