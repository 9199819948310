@import "./Colors.scss";


.flex-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.flex-center-2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}


.flex-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flex-column-2 {
    display: flex;
    align-items: center;
 
    flex-direction: column;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-sb {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.flex-sb-2 {
    display: flex;
    justify-content: flex-start;
    flex-wrap:wrap;

    

  
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}



.flex-left-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
