@import './Animatios.scss';
@import './Boilerpolate.scss';
@import './Buttons.scss';
@import './Colors.scss';
@import './FlexTools.scss';
@import './Navbar.scss';
@import './Sections.scss';
@import './Loaders.scss';

@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@500&display=swap');

body {
  font-family: 'Comfortaa', cursive;
  box-sizing: border-box !important;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;

  &:active {
    outline: none !important;
    box-shadow: none !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn {
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    outline: none !important;
    box-shadow: none !important;
  }

  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.custom-select {
  outline: none !important;
  box-shadow: none !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
