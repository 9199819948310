h1 {
  font-weight: 800 !important;
  font-size: 40px !important;
}

.home-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 348px);
}



.language-dropdown {
  position: relative;
  float: right !important;
  margin-top: 10px !important;
}

.navbar-nav > li > .dropdown-menu {
  background-color: #ffffff !important;
}

.dropdown-white,
.dropdown-white:hover {
  background-color: #ffffff !important;
  border-color: rgb(255, 255, 255) !important;
  color: #000 !important;
  font-weight: 700 !important;
}
.dropdown-white:active {
  background-color: #c1c1c1 !important;
  border-color: #c1c1c1 !important;
}
.dropdown-white:visited {
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
}
