@import "./Colors.scss";


.btn-custom-1 {
    border: 1.5px solid white;
    color: white;
    transition: 0.3s;
   

    span {
        color: white;
     transition: 0.3s;
    }

    i {
        color: white;
    }

    &:hover {
        background-color: white;
        color: white;

        span {
            color: $primaryColor;
            
        }

        i {
            color: $primaryColor;
        }
    }
}


.btn-custom-2 {
    border: 2px solid $primaryColor;
    color: $primaryColor;
    transition: 0.3s;
    background-color: white;
   

    span {
        color: $primaryColor;
     transition: 0.3s;
    }

    i {
        color: $primaryColor;
        transition: 0.3s;
    }

    &:hover {
        background-color: $primaryColor;
        color: white;

        span {
            color: white;
            
        }

        i {
            color: white;
        }
    }
}


.btn-custom-3 {
    background-color: $primaryColor;
    color: white;

    span {
        color: white;
    }

    i {
        color: white;
    }

    &:hover {
        color: white;
        span {
            color: white !important;
        }

        i {
            color: white !important;
        }
    }
}

.btn-custom-4 {
    background-color: $thirdColor;
    color: white;

    span {
        color: white;
    }

    i {
        color: white;
    }

    &:hover {
        color: white;
        span {
            color: white !important;
        }

        i {
            color: white !important;
        }
    }
}