.timeRangePicker {
  border: none !important;

  & > div {
    border: none !important;
    padding: 5px;
    background-color: var(--mui-palette-background-default);
    border-radius: var(--border-radius);
  }

  &
    div[class='react-timerange-picker__clock react-timerange-picker__clock--open'] {
    border-radius: var(--border-radius);
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  }
}
