.product-container {
  /* background-color: #f6f6f6; */
  width: 100%;

  padding: 20px;
  justify-content: space-between;
}

.title {
  text-align: start;
  font-size: 20px;
  font-weight: 700;
}

.description {
  text-align: start;
  font-size: 14px;
  color: #7c7c7c;
  overflow: hidden;
}

.product-image {
  width: 100px;
  height: 100px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 5px;
  background-color: rgb(231, 231, 231);
  margin-left: 10px;
}

.price {
  font-weight: 700;
  text-align: start;
  font-size: 20px;
  margin: 0px 0px 0px 0px;
  
}

.unit-price-container {
  display: flex;
  height: 25px;

  align-items: flex-end;
  padding: 0px !important;
}

.unit-price {
  text-align: start;
  font-size: 12px;
  bottom: 0px;
  margin: 0px !important;
}

.product-container-tools {
  /* border-bottom: 1px solid #d6d6d6; */
  width: 100%;

  /* background-color: #f6f6f6; */
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  padding-top: 2px;

}

.add-to-cart-button {
  margin: 0px !important;
  padding-top: auto !important;
  padding-bottom: auto !important;
  vertical-align: middle !important;
  min-width: 100px;
  font-weight: 700 !important;
}

.box-button {
  width: 40px !important;
  height: 40px !important;
  font-weight: 700 !important;
}

.quantity-text {
  text-align: center !important;
  font-size: 20px !important;
  line-height: 30px !important;
  padding: 0px !important;
  margin: 0px !important;
}

.tool-container {
  align-items: center !important;
  vertical-align: middle;
}
